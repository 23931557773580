import { makeAutoObservable, runInAction } from 'mobx'
import Api from '../utils/api'
import { Logger } from '../utils/log'

import ApplicationStore from './ApplicationStore'

// User data stored in store
export interface IUser {
  username: string
  isAdmin?: boolean
  isSupport?: boolean
}

// Data that can be send to the API for updating a user
interface IUserData {
  username: string
  isAdmin?: boolean
  isSupport?: boolean
  password?: string
  email?: string
}

class UserStore {
  private rootStore: ApplicationStore
  public user: IUser | undefined
  public isLoading: boolean

  public constructor(rootStore: ApplicationStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
    this.user = undefined
    this.isLoading = true
  }

  public login = async (username: string, password: string) => {
    this.isLoading = true
    try {
      const res = await Api.postJson('/login', {
        username,
        password
      })
      runInAction(() => {
        this.user = res.data
        this.isLoading = false
      })
      Logger.log('Logged in user', res.data)
    } catch (error) {
      runInAction(() => {
        this.isLoading = false
        this.rootStore.uiStore.setSnackbarError(
          error.response?.status === 401
            ? 'Benutzername oder Passwort falsch'
            : 'Fehler beim Einloggen.'
        )
      })
    }
  }

  public logout = async () => {
    try {
      const res = await Api.get('/logout')

      if (res.data === 'success') {
        runInAction(() => {
          this.user = undefined
          this.isLoading = false
        })
      }
    } catch (error) {
      this.rootStore.uiStore.setSnackbarError('Fehler beim Ausloggen.')
    }
  }

  public receiveUser = async () => {
    this.isLoading = true
    try {
      const res = await Api.get('/user')
      runInAction(() => {
        this.user = res.data
        this.isLoading = false
      })
    } catch (error) {
      runInAction(() => {
        this.isLoading = false
      })
    }
  }

  public updateUser = async (data: IUserData) => {
    this.isLoading = true

    try {
      const res = await Api.putJson('/updateUser', data)
      runInAction(() => {
        this.user = res.data
        this.isLoading = false
        this.rootStore.uiStore.setSnackbarSuccess('Ihre Daten wurden gespeichert.')
      })
      Logger.log('Updated user', res.data)
    } catch (error) {
      runInAction(() => {
        this.isLoading = false
        this.rootStore.uiStore.setSnackbarError('Fehler beim Speichern.')
      })
    }
  }

  public get isLoggedIn() {
    return this.user !== undefined
  }
}

export default UserStore

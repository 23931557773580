import React from 'react'
import { IHeatloadElementHP } from '../../models/settingsInterfaces'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import { Utility } from '../../utils/Utility'
import NumberFormatCustom from '../utilComponents/NumberFormat'
import { observer } from 'mobx-react-lite'

// data in child component
interface IHeatLoadHP {
  heatload: number | undefined
  investmentCost: number | undefined
  jaz: number | undefined
  monthlyRate: number | undefined
}

interface IHeatloadElementHPProps {
  element: IHeatloadElementHP
  fieldKey: string
  baseId?: string
  values?: any
  updateData?: (key: string, data: any) => void
}

// Inputs for child component
interface IHeatloadHPPriceInputsProps {
  baseId: string
  index: number
  hlPrice: IHeatLoadHP
  changeCb: (
    index: number,
    field: 'heatload' | 'investmentCost' | 'monthlyRate' | 'jaz',
    newValue: number | undefined
  ) => void
}

/** observer-Component */
/**
 * @param element - Object containing the properties of the input element.
 * For configurator settings, this data comes from the configuration file.
 * @param fieldKey - For configurator settings, this is an identifier for
 * the setting. It is also used for building the ids of text fields.
 * @param baseId - Optional separate base for the ids of inputs. If not
 * supplied, fieldKey will be used. This might be necessary if the fielKey is not
 * unique across the page.
 * @param values - The data that can be edited in this input
 * @param updateData - 'OnChange' callback for updating data. Will be passed
 * the fieldKey and an object containing the prices and runtime (the latter is
 * currently not used).
 */
const HeatloadElementHP: React.FC<IHeatloadElementHPProps> = ({
  element,
  fieldKey,
  baseId,
  values,
  updateData
}: IHeatloadElementHPProps) => {
  // Keeps track of 'HeatloadPriceInputs' child components state
  const prices = values ? [...values] : element?.default_data || []

  if (!baseId) {
    baseId = fieldKey
  }

  // Used in 'HeatloadPriceInputs' child components
  function handlePriceChange(
    index: number,
    field: 'heatload' | 'jaz' | 'monthlyRate' | 'investmentCost',
    newValue: number | undefined
  ) {
    if (updateData) {
      const newPrices = [...prices]
      newPrices[index] = { ...newPrices[index], [field]: newValue }
      updateData(fieldKey, newPrices)
    }
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant='body1'>
          <b>{element.title}</b>
        </Typography>
        {element.description && <Typography variant='body1'>{element.description}</Typography>}
      </Grid>
      {/* {prices?.current?.map((price: IHeatLoad, index: number) => ( */}
      {prices?.map((price: IHeatLoadHP, index: number) => (
        <HeatloadHPPriceInputs
          key={`priceInput_${index}`}
          baseId={baseId as string}
          index={index}
          hlPrice={price}
          changeCb={handlePriceChange}
        />
      ))}
    </Grid>
  )
}

/** Contains a set of input fields with labels for heatload, price and monthly rate */
const HeatloadHPPriceInputs: React.FC<IHeatloadHPPriceInputsProps> = ({
  baseId,
  index,
  hlPrice,
  changeCb
}: IHeatloadHPPriceInputsProps) => {
  const price = hlPrice || { heatload: 0, investmentCost: 0, jaz: 0, monthlyRate: 0 }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    // Convert input to positive number if needed
    const val = event.target.value ? +Utility.stripNum(event.target.value) : undefined

    // Update prices ref in parent component
    changeCb(index, event.target.name as 'heatload' | 'jaz' | 'monthlyRate' | 'investmentCost', val)
  }

  return (
    <Grid container item xs={12} spacing={1}>
      {/* ---- heatload ---- */}
      <Grid item xs={12} sm={4}>
        <Typography variant='body1' component='label' htmlFor={`${baseId}-hl-${index}`}>
          Heizlast der Pumpe (kW)
        </Typography>
      </Grid>
      <Grid item xs={12} sm={8}>
        <TextField
          type='text'
          inputProps={{
            decimalScale: 2,
            decimalSeparator: ',',
            thousandSeparator: '.',
            isNumericString: true,
            maxLength: 2000
          }}
          id={`${baseId}-hl-${index}`}
          name='heatload'
          value={String(price.heatload)}
          fullWidth
          variant='outlined'
          size='small'
          onChange={handleChange}
          InputProps={{
            inputComponent: NumberFormatCustom as any
          }}
        />
      </Grid>

      {/* ---- investmentCost ---- */}
      <Grid item xs={12} sm={4}>
        <Typography variant='body1' component='label' htmlFor={`${baseId}-pr-${index}`}>
          Investitionskosten (€)
        </Typography>
      </Grid>
      <Grid item xs={12} sm={8}>
        <TextField
          inputProps={{
            maxLength: 2000,
            decimalScale: 0,
            decimalSeparator: ',',
            thousandSeparator: '.',
            fixedDecimalScale: true,
            isNumericString: true
          }}
          id={`${baseId}-pr-${index}`}
          name='investmentCost'
          value={String(price.investmentCost)}
          fullWidth
          variant='outlined'
          size='small'
          onChange={handleChange}
          InputProps={{
            inputComponent: NumberFormatCustom as any
          }}
        />
      </Grid>

      {/* ---- Jahresarbeitszahl ---- */}
      <Grid item xs={12} sm={4}>
        <Typography variant='body1' component='label' htmlFor={`${baseId}-jaz-${index}`}>
          Jahresarbeitszahl
        </Typography>
      </Grid>
      <Grid item xs={12} sm={8}>
        <TextField
          inputProps={{
            maxLength: 2000,
            decimalScale: 2,
            decimalSeparator: ',',
            thousandSeparator: '.',
            fixedDecimalScale: true,
            isNumericString: true
          }}
          id={`${baseId}-jaz-${index}`}
          name='jaz'
          value={String(price.jaz)}
          fullWidth
          variant='outlined'
          size='small'
          onChange={handleChange}
          InputProps={{
            inputComponent: NumberFormatCustom as any
          }}
        />
      </Grid>

      {/* ---- Monthly Rate ---- */}
      <Grid item xs={12} sm={4}>
        <Typography variant='body1' component='label' htmlFor={`${baseId}-mr-${index}`}>
          Monatliche Pachtrate (€/Monat)
        </Typography>
      </Grid>
      <Grid item xs={12} sm={8}>
        <TextField
          inputProps={{
            maxLength: 2000,
            decimalScale: 0,
            decimalSeparator: ',',
            thousandSeparator: '.',
            fixedDecimalScale: true,
            isNumericString: true
          }}
          id={`${baseId}-mr-${index}`}
          name='monthlyRate'
          value={String(price.monthlyRate)}
          fullWidth
          variant='outlined'
          size='small'
          onChange={handleChange}
          InputProps={{
            inputComponent: NumberFormatCustom as any
          }}
        />
      </Grid>
    </Grid>
  )
}

export default observer(HeatloadElementHP)

import React from 'react'
import {
  InputBaseComponentProps,
  createStyles,
  Theme,
  withStyles,
  FormControl,
  InputLabel,
  FormHelperText
} from '@material-ui/core'
import InputBase from '@material-ui/core/InputBase'

type TTextFieldInnerLabelProps = InputBaseComponentProps & {
  id?: string
  label: string
  helperText?: string | null
}

const TextFieldLabelOnTop: React.FC<TTextFieldInnerLabelProps> = ({
  id = 'input',
  label,
  helperText,
  ...otherProps
}: TTextFieldInnerLabelProps) => (
  <FormControl error={otherProps.error}>
    <InputLabel shrink htmlFor={id} error={otherProps.error}>
      {label}
    </InputLabel>
    <CustomInput id={id} inputProps={{ ...otherProps }} />
    <FormHelperText id={`${id}_helper-text`} error={otherProps.error}>
      {helperText}
    </FormHelperText>
  </FormControl>
)

const CustomInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(2)
      }
    },
    input: {
      width: '100%',
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.common.white,
      border: `1px solid ${theme.custom.defaultBorderColor}`,
      fontSize: 16,
      padding: '10px 12px',
      '&:focus': {
        borderColor: theme.palette.primary.main
      },
      '&.Mui-error': {
        borderColor: theme.palette.error.main
      },
      error: {}
    }
  })
)(InputBase)

export default TextFieldLabelOnTop

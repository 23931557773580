import React, { ReactNode, useState } from 'react'
import {
  createStyles,
  makeStyles,
  Theme,
  Box,
  Button,
  Grid,
  AppBar,
  Toolbar,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core'
import clsx from 'clsx'

import { FileLibraryListItem } from '../../types/'
import FileLibraryCard from './FileLibraryCard'
import FileLibraryPager from './FileLibraryPager'
import { useSettings } from '../context/SettingsContext'
import { FileLibraryProps } from '../../types/components/FileLibrary'
import { SortBy, sortByOptions } from '../../types/sortOptions'
import accept from 'attr-accept'
import { defaultProps } from '../utils/defaultProps'

/** Checks if the values of an array are all present as keys of a Map */
function checkOptionsTexts<K, V>(optionsTexts: Map<K, V>, validKeys: K[]): boolean {
  let result = true
  const optionsKeys = Array.from(optionsTexts.keys())
  validKeys.forEach((key) => {
    if (!optionsKeys.includes(key)) {
      result = false
    }
  })
  return result
}

const FileLibrary: React.FC<FileLibraryProps> = ({ libraryCardComponent }: FileLibraryProps) => {
  const { settings } = useSettings()
  const {
    acceptedTypes,
    fileLibraryList,
    defaultSortProperty = defaultProps.defaultSortProperty,
    defaultSortAscending = defaultProps.defaultSortAscending,
    fileSelectCallback,
    fileDeleteCallback,
    itemsPerPage = defaultProps.itemsPerPage,
    selectBtnText = defaultProps.selectBtnText,
    deleteBtnText = defaultProps.deleteBtnText,
    sortLabelText = defaultProps.sortLabelText,
    sortOptionsTexts = defaultProps.sortOptionsTexts,
    sorOrderLabelText = defaultProps.sorOrderLabelText,
    sorOrderOptionsTexts = defaultProps.sorOrderOptionsTexts,
    showTitle = defaultProps.showTitle,
    showDescription = defaultProps.showDescription
  } = settings

  const [selectedItem, setSelectedItem] = useState<FileLibraryListItem | undefined>(undefined)
  const [page, setPage] = useState<number>(1)
  const [sortBy, setSortBy] = useState<SortBy>(defaultSortProperty)
  const [sortAsc, setSortAsc] = useState<boolean>(defaultSortAscending)

  // Remove unaccepted files from list
  let fileLibraryListFiltered = fileLibraryList
  if (acceptedTypes) {
    fileLibraryListFiltered = fileLibraryList.filter(fileTypesFilter)
  }

  // Check if the strings for the sorting-options match the available options
  const sortOptionsTextValid = checkOptionsTexts<string, string>(
    sortOptionsTexts,
    (sortByOptions as unknown) as string[]
  )
  if (!sortOptionsTextValid) {
    console.warn(
      'Names for sorting options do not match the available options. Using variable names instead.'
    )
  }

  const sortOrderOptionsTextValid = checkOptionsTexts<boolean, string>(sorOrderOptionsTexts, [
    true,
    false
  ])
  if (!sortOrderOptionsTextValid) {
    console.warn(
      'Names for sorting order options do not match the available options. Using defaults instead.'
    )
  }

  function handleDelete() {
    if (fileDeleteCallback) {
      fileDeleteCallback(selectedItem as FileLibraryListItem)
    }
  }

  function handleSelect() {
    if (fileSelectCallback) {
      fileSelectCallback(selectedItem as FileLibraryListItem)
    }
  }

  function handleChangeSortBy(event: React.ChangeEvent<{ value: unknown }>) {
    setSortBy(event.target.value as SortBy)
  }

  function handleChangeSortAsc(event: React.ChangeEvent<{ value: unknown }>) {
    setSortAsc(!!event.target.value)
  }

  function sortArray(a: FileLibraryListItem, b: FileLibraryListItem): -1 | 0 | 1 {
    try {
      const property = sortBy
      let valA: any = property !== undefined ? a[property] : 0
      let valB: any = property !== undefined ? b[property] : 0

      // If string, ignore upper and lowercase
      if (typeof valA === 'string') {
        valA = valA.toUpperCase()
      }
      if (typeof valB === 'string') {
        valB = valB.toUpperCase()
      }

      if (sortAsc) {
        if (!valA) {
          return 1
        } else if (!valB) {
          return -1
        } else {
          return valA < valB ? -1 : 1
        }
      } else {
        if (!valB) {
          return 1
        } else if (!valA) {
          return -1
        } else {
          return valA > valB ? -1 : 1
        }
      }
    } catch {
      return 0
    }
  }

  function fileTypesFilter(fileItem: FileLibraryListItem): boolean {
    return accept({ name: fileItem.fileName, type: fileItem.type || '' }, acceptedTypes || '')
  }

  function handleCardClick(element: FileLibraryListItem) {
    if (selectedItem?._id !== element._id) {
      setSelectedItem(element)
    } else {
      handleSelect()
    }
  }

  function renderList(): ReactNode[] {
    if (!fileLibraryListFiltered) {
      return []
    }

    const arrayStart = (page - 1) * itemsPerPage
    let arrayEnd = arrayStart + itemsPerPage
    if (arrayEnd > fileLibraryListFiltered.length) {
      // If calculated end extends past length of actual array
      // Set calculated end as length of array
      arrayEnd = fileLibraryListFiltered.length
    }

    return [...fileLibraryListFiltered]
      .sort(sortArray)
      .slice(arrayStart, arrayEnd)
      .map((element: FileLibraryListItem, index: number) => (
        <Grid
          item
          key={index}
          xs={6}
          sm={4}
          md={3}
          lg={2}
          className='mb-3'
          onClick={() => handleCardClick(element)}>
          {React.createElement(libraryCardComponent as React.FC<FileLibraryListItem>, {
            selectedItem,
            ...element,
            showTitle,
            showDescription
          })}
        </Grid>
      ))
  }

  function toolBar(): ReactNode {
    const classes = useStyles()

    // Remve title from options if showTitle option is false
    let sortSelectOptions: string[] = [...sortByOptions]
    if (!showTitle) {
      sortSelectOptions = sortByOptions.filter((option) => option !== 'title')
    }

    return (
      <AppBar position='static' color='default'>
        <Toolbar variant='dense' classes={{ root: classes.toolbarRoot }}>
          <Box>
            <FormControl variant='outlined' classes={{ root: classes.formControlRoot }}>
              <InputLabel
                id='select-sort-by-label'
                shrink={false}
                classes={{ root: classes.inputLabelRoot }}>
                {sortLabelText}
              </InputLabel>
              <Select
                labelId='select-sort-by-label'
                id='select-sort-by'
                classes={{ root: classes.selectRoot }}
                value={sortBy}
                onChange={handleChangeSortBy}>
                {sortSelectOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {sortOptionsTextValid ? sortOptionsTexts.get(option) : option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl variant='outlined' classes={{ root: classes.formControlRoot }}>
              <InputLabel
                id='select-sort-order-label'
                shrink={false}
                classes={{ root: classes.inputLabelRoot }}>
                {sorOrderLabelText}
              </InputLabel>
              <Select
                labelId='select-sort-order-label'
                id='select-sort-order'
                classes={{ root: classes.selectRoot }}
                value={sortAsc ? 1 : 0}
                onChange={handleChangeSortAsc}>
                <MenuItem value={1}>
                  {sortOrderOptionsTextValid
                    ? sorOrderOptionsTexts.get(true)
                    : defaultProps.sorOrderOptionsTexts.get(true)}
                </MenuItem>
                <MenuItem value={0}>
                  {sortOrderOptionsTextValid
                    ? sorOrderOptionsTexts.get(false)
                    : defaultProps.sorOrderOptionsTexts.get(false)}
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box py={1}>
            {selectedItem && (
              <>
                {fileDeleteCallback !== undefined && (
                  <Button
                    variant='contained'
                    color='primary'
                    className={clsx(classes.subitRowBtn, classes.btnErrorColor)}
                    onClick={handleDelete}>
                    {deleteBtnText}
                  </Button>
                )}
                <Button
                  variant='contained'
                  color='primary'
                  className={classes.subitRowBtn}
                  onClick={handleSelect}>
                  {selectBtnText}
                </Button>
              </>
            )}
          </Box>
        </Toolbar>
      </AppBar>
    )
  }

  function pagerRow(): ReactNode {
    function pagerCallback(idx: number) {
      setPage(idx)
    }
    return (
      fileLibraryListFiltered.length > itemsPerPage && (
        <Box display='flex' justifyContent='center' py={3}>
          <FileLibraryPager
            count={fileLibraryListFiltered.length}
            page={page}
            pagerCallback={pagerCallback}
          />
        </Box>
      )
    )
  }

  return (
    <>
      {toolBar()}
      <Box p={3}>
        <Grid container spacing={2}>
          {renderList()}
        </Grid>
        {pagerRow()}
      </Box>
    </>
  )
}

FileLibrary.defaultProps = {
  libraryCardComponent: FileLibraryCard
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControlRoot: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      '&:not(:last-child)': {
        marginRight: theme.spacing(2)
      },
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        alignItems: 'center'
      }
    },
    inputLabelRoot: {
      position: 'static',
      transform: 'translate(0) scale(1)',
      marginBottom: theme.spacing(0.5),
      marginRight: 'initial',
      [theme.breakpoints.up('md')]: {
        marginBottom: 'initial',
        marginRight: theme.spacing(1)
      }
    },
    subitRowBtn: {
      '&:not(:last-child)': {
        marginRight: theme.spacing(1)
      }
    },
    btnErrorColor: {
      backgroundColor: theme.palette.error.main
    },
    toolbarRoot: {
      justifyContent: 'space-between',
      flexDirection: 'column',
      alignItems: 'flex-start',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
        alignItems: 'center'
      },
      [theme.breakpoints.up('md')]: {
        paddingTop: 'initial',
        paddingBottom: 'initial'
      }
    },
    selectRoot: {
      paddingTop: 12,
      paddingBottom: 12
    }
  })
)

export default FileLibrary

import _ from 'lodash'
import { runInAction, makeAutoObservable, toJS, extendObservable } from 'mobx'

import { IQuestion, ISubQuestion } from '../models/questionsInterfaces'
import { ICategory, IMultiInputElement, ISettings } from '../models/settingsInterfaces'
import Api from '../utils/api'
import { Logger } from '../utils/log'
import ApplicationStore from './ApplicationStore'

export interface ISettingsData {
  [key: string]: unknown
}

class FormStore {
  private rootStore: ApplicationStore
  private configReady: boolean = false
  public CISettings: ISettings | undefined = undefined
  public CISettingsData: any = undefined
  public CISettingsLoaded: boolean = false
  public CISettingsDataLoaded: boolean = false

  public Questions: ISettings | undefined = undefined
  public QuestionsData: any = undefined
  public QuestionsLoaded: boolean = false
  public QuestionsDataLoaded: boolean = false

  public WTSettings: ISettings | undefined = undefined
  public WTSettingsData: any = undefined
  public WTSettingsLoaded: boolean = false
  public WTSettingsDataLoaded: boolean = false

  public EHSettings: ISettings | undefined = undefined
  public EHSettingsData: any = undefined
  public EHSettingsLoaded: boolean = false
  public EHSettingsDataLoaded: boolean = false

  public PVSettings: ISettings | undefined = undefined
  public PVSettingsData: any = undefined
  public PVSettingsLoaded: boolean = false
  public PVSettingsDataLoaded: boolean = false

  public WCEmailSettings: ISettings | undefined = undefined
  public WCEmailSettingsData: any = undefined
  public WCEmailSettingsLoaded: boolean = false
  public WCEmailSettingsDataLoaded: boolean = false

  public EHEmailSettings: ISettings | undefined = undefined
  public EHEmailSettingsData: any = undefined
  public EHEmailSettingsLoaded: boolean = false
  public EHEmailSettingsDataLoaded: boolean = false

  public PVEmailSettings: ISettings | undefined = undefined
  public PVEmailSettingsData: any = undefined
  public PVEmailSettingsLoaded: boolean = false
  public PVEmailSettingsDataLoaded: boolean = false

  public NotifierSettings: ISettings | undefined = undefined
  public NotifierSettingsData: any = undefined
  public NotifierSettingsLoaded: boolean = false
  public NotifierSettingsDataLoaded: boolean = false

  public PVNotifierSettings: ISettings | undefined = undefined
  public PVNotifierSettingsData: any = undefined
  public PVNotifierSettingsLoaded: boolean = false
  public PVNotifierSettingsDataLoaded: boolean = false

  public EHWelcomeSettings: ISettings | undefined = undefined
  public EHWelcomeSettingsData: any = undefined
  public EHWelcomeSettingsLoaded: boolean = false
  public EHWelcomeSettingsDataLoaded: boolean = false

  private defaultnumSubQuestions = 9
  public maxNumSubQuestions = this.defaultnumSubQuestions

  private elementsPerCategory: any = undefined // TODO

  public constructor(rootStore: ApplicationStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  /* ------ Computeds and getters ------ */
  public get numQuestions() {
    return this.QuestionsData?.questions?.length || 0
  }

  public get questionsDataHasChanges() {
    let isequal = true

    if (this.QuestionsData && this.QuestionsData.initialSetting) {
      const { initialSetting, ...newQuestions } = this.QuestionsData
      if (newQuestions?.questions && initialSetting.questions) {
        isequal = _.isEqual(newQuestions.questions, initialSetting.questions)
      } else {
        isequal = _.isEqual(newQuestions, initialSetting)
      }
    }

    return !isequal
  }

  /* ------ Configuration ------ */
  /** Get configuration file from API */
  public receiveConfiguration = async () => {
    if (!this.configReady) {
      try {
        const res = await Api.get('/api/config')
        runInAction(() => {
          this.CISettings = res.data.forms.cisettings
          this.CISettingsLoaded = true
          this.Questions = res.data.forms.questions
          this.QuestionsLoaded = true
          this.WTSettings = res.data.forms.wtsettings
          this.WTSettingsLoaded = true
          this.EHSettings = res.data.forms.ehsettings
          this.EHSettingsLoaded = true
          this.PVSettings = res.data.forms.pvsettings
          this.PVSettingsLoaded = true
          this.EHEmailSettings = res.data.forms.ehemailsettings
          this.EHEmailSettingsLoaded = true
          this.WCEmailSettings = res.data.forms.wcemailsettings
          this.WCEmailSettingsLoaded = true
          this.PVEmailSettings = res.data.forms.pvemailsettings
          this.PVEmailSettingsLoaded = true
          this.NotifierSettings = res.data.forms.notifiersettings
          this.NotifierSettingsLoaded = true
          this.PVNotifierSettings = res.data.forms.pvnotifiersettings
          this.PVNotifierSettingsLoaded = true
          this.EHWelcomeSettings = res.data.forms.ehwelcomesettings
          this.EHWelcomeSettingsLoaded = true
          this.elementsPerCategory = this.getElementsPerCategory(res.data.forms)
          this.configReady = true
          this.getMaxNumSubQuestions()
        })
      } catch (error) {
        console.error(error)
        runInAction(() => {
          this.CISettingsLoaded = true
          this.QuestionsLoaded = true
          this.WTSettingsLoaded = true
          this.EHSettingsLoaded = true
          this.PVSettingsLoaded = true
          this.EHEmailSettingsLoaded = true
          this.WCEmailSettingsLoaded = true
          this.PVEmailSettingsLoaded = true
          this.NotifierSettingsLoaded = true
          this.PVNotifierSettingsLoaded = true
          this.EHWelcomeSettingsLoaded = true
        })
      }
    }
  }

  /* ------ Update Methods (add, remove or update questions) ------ */
  public addQuestion = (q: IQuestion) => {
    if (!this.QuestionsData) {
      this.QuestionsData = {}
    }

    if (!this.QuestionsData.questions) {
      // this.QuestionsData.questions = []
      extendObservable(this.QuestionsData, { questions: [] })
    }

    this.QuestionsData.questions.push(q)
  }

  public deleteQuestion = (id: string) => {
    const index = this.QuestionsData.questions.findIndex((q: IQuestion) => q.id === id)

    if (index >= 0) {
      this.QuestionsData.questions.splice(index, 1)

      for (let i = index; i < this.QuestionsData.questions.length; i++) {
        this.QuestionsData.questions[i].order -= 1
      }
    } else {
      console.error('Could not delete question with ID ' + id + '. ID not found.')
    }
  }

  public updateQuestion = (id: string, field: string, value: any) => {
    if (this.QuestionsData?.questions) {
      const index = this.QuestionsData.questions.findIndex((q: IQuestion) => q.id === id)

      if (index >= 0) {
        this.QuestionsData.questions[index][field] = value
      } else {
        console.error('Could not update question with ID ' + id + '. ID not found.')
      }
    } else {
      console.error('Could not update question with ID ' + id + '. No questions available.')
    }
  }

  public addEmptySubQuestion = (questionId: string) => {
    const questionIndex = this.QuestionsData.questions.findIndex(
      (q: IQuestion) => q.id === questionId
    )

    if (!this.QuestionsData.questions[questionIndex].subQuestionsArray) {
      this.QuestionsData.questions[questionIndex].subQuestionsArray = []
    }

    this.QuestionsData.questions[questionIndex].subQuestionsArray.push({
      value: undefined,
      cost_absolute: undefined,
      cost_procentual: undefined
    })
  }

  public deleteSubQuestion = (questionId: string, subQuestionIndex: number) => {
    const questionIndex = this.QuestionsData.questions.findIndex(
      (q: IQuestion) => q.id === questionId
    )
    this.QuestionsData.questions[questionIndex].subQuestionsArray.splice(subQuestionIndex, 1)
  }

  public updateSubQuestion = (
    questionId: string,
    subQuestionIndex: number,
    key: string,
    value: string | number | undefined
  ) => {
    const questionIndex = this.QuestionsData.questions.findIndex(
      (q: IQuestion) => q.id === questionId
    )
    this.QuestionsData.questions[questionIndex].subQuestionsArray[subQuestionIndex][key] = value
  }

  public moveQuestion = (id: string, oldIndex: number, newIndex: number) => {
    const question: IQuestion = toJS(
      this.QuestionsData.questions.find((item: IQuestion) => item.id === id)
    )

    if (!question) {
      console.error('Could not find question with ID ' + id)
      return
    }

    const currentOrder =
      typeof question.order === 'string' ? parseInt(question.order, 10) : question.order

    if (currentOrder !== oldIndex) {
      console.error(
        'Could not change order, because old order does not match questions order property.' +
          ' Provided order: ' +
          oldIndex +
          ', order from question: ' +
          currentOrder
      )
      return
    }

    const questions = toJS(this.QuestionsData.questions)

    // Move question in array
    questions.splice(oldIndex - 1, 1)
    questions.splice(newIndex - 1, 0, question)

    // Correct order property of elements between old and new positions
    if (newIndex >= oldIndex) {
      for (let i = oldIndex - 1; i < newIndex - 1; i++) {
        questions[i].order = this.changeOrder(questions[i].order, 'dec')
      }
    } else {
      for (let i = newIndex - 1; i <= oldIndex - 1; i++) {
        questions[i].order = this.changeOrder(questions[i].order, 'inc')
      }
    }
    questions[newIndex - 1].order = newIndex

    this.QuestionsData.questions = questions
  }

  public moveQuestionUpOrDown = (q: IQuestion, up: boolean) => {
    const currentOrder = typeof q.order === 'string' ? parseInt(q.order, 10) : q.order

    // Check if we hit a dead-end
    if (up && currentOrder === 1) {
      Logger.log(`Order ${q.order} is not going up, returning`)
      return
    } else if (!up && currentOrder === this.numQuestions) {
      Logger.log(`Order ${q.order} is not going down, returning`)
      return
    }

    // Get old question
    const oldData = this.QuestionsData.questions.find((item: IQuestion) => item.id === q.id)

    if (!oldData) {
      console.error('Could not find question with ID ' + q.id)
      return
    }

    // Move stuff around
    if (up) {
      oldData.order = currentOrder - 1
      this.QuestionsData.questions[currentOrder - 1] = this.QuestionsData.questions[
        currentOrder - 2
      ]
      this.QuestionsData.questions[currentOrder - 1].order = currentOrder
      this.QuestionsData.questions[currentOrder - 2] = oldData
    } else {
      oldData.order = currentOrder + 1
      this.QuestionsData.questions[currentOrder - 1] = this.QuestionsData.questions[currentOrder]
      this.QuestionsData.questions[currentOrder - 1].order = currentOrder
      this.QuestionsData.questions[currentOrder] = oldData
    }
  }

  /* --- Methods for getting information about a question or category --- */
  public settingHasChanges = (setting: any): boolean => {
    if (!!setting?.initialSetting) {
      const { initialSetting, ...newSetting } = setting
      return !_.isEqual(newSetting, initialSetting)
    } else {
      return !(typeof setting === undefined || setting === '')
    }
  }

  public questionHasChanges = (questionId: string): boolean => {
    if (this.QuestionsData?.initialSetting) {
      const questionNew: IQuestion =
        toJS(this.QuestionsData.questions?.find((item: IQuestion) => item.id === questionId)) ||
        undefined
      const questionOld: IQuestion =
        toJS(
          this.QuestionsData.initialSetting.questions?.find(
            (item: IQuestion) => item.id === questionId
          )
        ) || undefined

      return !_.isEqual(toJS(questionNew), toJS(questionOld))
    } else {
      return false
    }
  }

  /* Checks if there are changes in a particular settings category */
  public categoryHasChanges = (categoryId: string, settingsData: any): boolean => {
    const elements = this.elementsPerCategory[categoryId]

    if (settingsData?.initialSetting) {
      return (
        elements?.some((element: string) => {
          /* CI settings has an inner 'settings' property while all other settings do. */
          if (settingsData.settings) {
            return !_.isEqual(
              settingsData.settings[element],
              settingsData.initialSetting.settings[element]
            )
          } else {
            return !_.isEqual(settingsData[element], settingsData.initialSetting[element])
          }
        }) || false
      )
    } else {
      return elements?.some((element: string) => {
        /* CI settings has an inner 'settings' property while all other settings do. */
        if (settingsData.settings) {
          return !!settingsData.settings[element]
        } else {
          return !!settingsData[element]
        }
      })
    }
  }

  /* -------- Update Methods (update store) ------- */
  public setCIData = (key: string, value: any) => {
    if (!this.CISettingsData) {
      this.CISettingsData = {}
    }

    this.CISettingsData[key] = value
  }

  public setWTData = (key: string, value: any) => {
    if (!this.WTSettingsData) {
      this.WTSettingsData = {}
    }
    if (!this.WTSettingsData.settings) {
      this.WTSettingsData.settings = {}
    }

    this.WTSettingsData.settings[key] = value
  }

  public setEHData = (key: string, value: any) => {
    if (!this.EHSettingsData) {
      this.EHSettingsData = {}
    }
    if (!this.EHSettingsData.settings) {
      this.EHSettingsData.settings = {}
    }

    this.EHSettingsData.settings[key] = value
  }

  public setPVData = (key: string, value: any) => {
    if (!this.PVSettingsData) {
      this.PVSettingsData = {}
    }
    if (!this.PVSettingsData.settings) {
      this.PVSettingsData.settings = {}
    }

    this.PVSettingsData.settings[key] = value
  }

  public setWCEmailData = (key: string, value: any) => {
    if (!this.WCEmailSettingsData) {
      this.WCEmailSettingsData = {}
    }
    if (!this.WCEmailSettingsData.settings) {
      this.WCEmailSettingsData.settings = {}
    }

    this.WCEmailSettingsData.settings[key] = value
  }

  public setEHEmailData = (key: string, value: any) => {
    if (!this.EHEmailSettingsData) {
      this.EHEmailSettingsData = {}
    }
    if (!this.EHEmailSettingsData.settings) {
      this.EHEmailSettingsData.settings = {}
    }

    this.EHEmailSettingsData.settings[key] = value
  }

  public setPVEmailData = (key: string, value: any) => {
    if (!this.PVEmailSettingsData) {
      this.PVEmailSettingsData = {}
    }
    if (!this.PVEmailSettingsData.settings) {
      this.PVEmailSettingsData.settings = {}
    }

    this.PVEmailSettingsData.settings[key] = value
  }

  public setNotifierData = (key: string, value: any) => {
    if (!this.NotifierSettingsData) {
      this.NotifierSettingsData = {}
    }
    if (!this.NotifierSettingsData.settings) {
      this.NotifierSettingsData.settings = {}
    }

    this.NotifierSettingsData.settings[key] = value
  }

  public setPVNotifierData = (key: string, value: any) => {
    if (!this.PVNotifierSettingsData) {
      this.PVNotifierSettingsData = {}
    }
    if (!this.PVNotifierSettingsData.settings) {
      this.PVNotifierSettingsData.settings = {}
    }

    this.PVNotifierSettingsData.settings[key] = value
  }

  public setEHWelcomeData = (key: string, value: any) => {
    if (!this.EHWelcomeSettingsData) {
      this.EHWelcomeSettingsData = {}
    }
    if (!this.EHWelcomeSettingsData.settings) {
      this.EHWelcomeSettingsData.settings = {}
    }

    this.EHWelcomeSettingsData.settings[key] = value
  }

  /* -------- Update Methods (update store and send data to API) ------- */
  /* Methods that get triggered when user clicks 'save'. Components send their
  data to the store and the store sends it to the API */
  public updateCIData = async () => {
    await this.updateSetting('/api/updateCISettings', this.CISettingsData)
  }

  public updateQuestionsData = async () => {
    const questionsWithoutSubQuestionsArr = this.removeSubQuestionsArrayFromQuestions()
    await this.updateSetting('/api/updateQuestions', questionsWithoutSubQuestionsArr)
  }

  public updateWTData = async () => {
    await this.updateSetting('/api/updateWTSettings', this.WTSettingsData)
  }

  public updateEHData = async () => {
    await this.updateSetting('/api/updateEHSettings', this.EHSettingsData)
  }

  public updatePVData = async () => {
    await this.updateSetting('/api/updatePVSettings', this.PVSettingsData)
  }

  public updateWCEmailData = async () => {
    await this.updateSetting('/api/updateWCemailSettings', this.WCEmailSettingsData)
  }

  public updateEHEmailData = async () => {
    await this.updateSetting('/api/updateEHemailSettings', this.EHEmailSettingsData)
  }

  public updatePVEmailData = async () => {
    await this.updateSetting('/api/updatePVEmailSettings', this.PVEmailSettingsData)
  }

  public updateNotifierData = async () => {
    await this.updateSetting('/api/updateNotifierSettings', this.NotifierSettingsData)
  }

  public updatePVNotifierData = async () => {
    await this.updateSetting('/api/updatePVNotifierSettings', this.PVNotifierSettingsData)
  }

  public updateEHWelcomeData = async () => {
    await this.updateSetting('/api/updateEHwelcomeSettings', this.EHWelcomeSettingsData)
  }

  /* -------- Update Methods (receive data from API) ------- */
  public receiveCISettingsData = async () => {
    this.CISettingsDataLoaded = false

    try {
      const res = await Api.get('/api/cisettings')
      runInAction(() => {
        this.CISettingsData = res.data
        if (this.CISettingsData) {
          this.CISettingsData.initialSetting = res.data
        }
        this.CISettingsDataLoaded = true
        // this.initDefaultValues(this.CISettings, this.CISettingsData, this.setCIData)
      })
    } catch (error) {
      runInAction(() => {
        console.error(error)
        this.CISettingsDataLoaded = true
      })
    }
  }

  public receiveQuestionsData = async () => {
    this.QuestionsDataLoaded = false

    try {
      const res = await Api.get('/api/questions')
      runInAction(() => {
        this.QuestionsData = res.data
        this.addSubQuestionsArrayToQuestions()
        if (this.QuestionsData) {
          this.QuestionsData.initialSetting = toJS(this.QuestionsData)
        }
        this.QuestionsDataLoaded = true
      })
    } catch (error) {
      console.error(error)
      runInAction(() => {
        this.QuestionsDataLoaded = true
      })
    }
  }

  public receiveWTSettingsData = async () => {
    this.WTSettingsDataLoaded = false

    try {
      const res = await Api.get('/api/wtsettings')
      runInAction(() => {
        this.WTSettingsData = res.data
        if (this.WTSettingsData) {
          this.WTSettingsData.initialSetting = res.data
        }
        this.WTSettingsDataLoaded = true
      })
    } catch (error) {
      console.error(error)
      runInAction(() => {
        this.WTSettingsDataLoaded = true
      })
    }
  }

  public receiveEHSettingsData = async () => {
    this.EHSettingsDataLoaded = false

    try {
      const res = await Api.get('/api/ehsettings')
      runInAction(() => {
        this.EHSettingsData = res.data
        if (this.EHSettingsData) {
          this.EHSettingsData.initialSetting = res.data
        }
        this.EHSettingsDataLoaded = true
      })
    } catch (error) {
      console.error(error)
      runInAction(() => {
        this.EHSettingsDataLoaded = true
      })
    }
  }

  public receivePVSettingsData = async () => {
    this.PVSettingsDataLoaded = false
    try {
      const res = await Api.get('/api/pvsettings')
      runInAction(() => {
        this.PVSettingsData = res.data
        if (this.PVSettingsData) {
          this.PVSettingsData.initialSetting = res.data
        }
        this.PVSettingsDataLoaded = true
      })
    } catch (error) {
      console.error(error)
      runInAction(() => {
        this.PVSettingsDataLoaded = true
      })
    }
  }

  public receiveWCEmailSettingsData = async () => {
    this.WCEmailSettingsDataLoaded = false

    try {
      const res = await Api.get('/api/wcemailsettings')
      runInAction(() => {
        this.WCEmailSettingsData = res.data
        if (this.WCEmailSettingsData) {
          this.WCEmailSettingsData.initialSetting = res.data
        }
        this.WCEmailSettingsDataLoaded = true
      })
    } catch (error) {
      console.error(error)
      runInAction(() => {
        this.WCEmailSettingsDataLoaded = true
      })
    }
  }

  public receiveEHEmailSettingsData = async () => {
    this.EHEmailSettingsDataLoaded = false

    try {
      const res = await Api.get('/api/ehemailsettings')
      runInAction(() => {
        this.EHEmailSettingsData = res.data
        if (this.EHEmailSettingsData) {
          this.EHEmailSettingsData.initialSetting = res.data
        }
        this.EHEmailSettingsDataLoaded = true
      })
    } catch (error) {
      console.error(error)
      runInAction(() => {
        this.EHEmailSettingsDataLoaded = true
      })
    }
  }

  public receivePVEmailSettingsData = async () => {
    this.PVEmailSettingsDataLoaded = false

    try {
      const res = await Api.get('/api/pvemailsettings')
      runInAction(() => {
        this.PVEmailSettingsData = res.data
        if (this.PVEmailSettingsData) {
          this.PVEmailSettingsData.initialSetting = res.data
        }
        this.PVEmailSettingsDataLoaded = true
      })
    } catch (error) {
      console.error(error)
      runInAction(() => {
        this.PVEmailSettingsDataLoaded = true
      })
    }
  }

  public receiveNotifierSettingsData = async () => {
    this.NotifierSettingsDataLoaded = false

    try {
      const res = await Api.get('/api/notifiersettings')
      runInAction(() => {
        this.NotifierSettingsData = res.data
        if (this.NotifierSettingsData) {
          this.NotifierSettingsData.initialSetting = res.data
        }
        this.NotifierSettingsDataLoaded = true
      })
    } catch (error) {
      console.error(error)
      runInAction(() => {
        this.NotifierSettingsDataLoaded = true
      })
    }
  }

  public receivePVNotifierSettingsData = async () => {
    this.PVNotifierSettingsDataLoaded = false

    try {
      const res = await Api.get('/api/pvnotifiersettings')
      runInAction(() => {
        this.PVNotifierSettingsData = res.data
        if (this.PVNotifierSettingsData) {
          this.PVNotifierSettingsData.initialSetting = res.data
        }
        this.PVNotifierSettingsDataLoaded = true
      })
    } catch (error) {
      console.error(error)
      runInAction(() => {
        this.PVNotifierSettingsDataLoaded = true
      })
    }
  }

  public receiveEHWelcomeSettingsData = async () => {
    this.EHWelcomeSettingsDataLoaded = false

    try {
      const res = await Api.get('/api/ehwelcomesettings')

      runInAction(() => {
        this.EHWelcomeSettingsData = res.data
        if (this.EHWelcomeSettingsData) {
          this.EHWelcomeSettingsData.initialSetting = res.data
        }
        this.EHWelcomeSettingsDataLoaded = true
      })
    } catch (error) {
      console.error(error)
      runInAction(() => {
        this.EHWelcomeSettingsDataLoaded = true
      })
    }
  }

  /* -------- Helpers ------- */
  private updateSetting = async (
    uri: string,
    data: ISettingsData,
    callback?: () => Promise<any>
  ) => {
    this.rootStore.uiStore.setSavingDisabled(true)

    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { initialSetting, ...dataToSave } = data

      const res = await Api.postJson(uri, dataToSave)
      Logger.log(`${uri} response`, res.data)

      if (callback) {
        await callback()
      }
      runInAction(() => {
        this.rootStore.uiStore.setSavingDisabled(false)
        this.rootStore.uiStore.setDataSavedSnackbarSuccess()
      })
    } catch (error) {
      runInAction(() => {
        this.rootStore.uiStore.setSavingDisabled(false)
        this.rootStore.uiStore.setDataSavedSnackbarError()
      })
    }
  }

  private changeOrder = (oldOrder: string | number, direction: 'inc' | 'dec'): number => {
    let oldOrderNum = typeof oldOrder === 'string' ? parseInt(oldOrder, 10) : oldOrder

    if (direction === 'inc') {
      oldOrderNum += 1
    } else {
      oldOrderNum -= 1
    }
    return oldOrderNum
  }

  /* Temprarily store sub-questions of the a question of type 'multi' in an array format to 
  facilitate adding and removing sub-questions in the UI. It will be removed before the 
  questions data is stored in the database. */
  private addSubQuestionsArrayToQuestions = () => {
    // this.QuestionsDataInitial.questions.forEach(
    //   (element: IQuestion, idx: number, questions: IQuestion[]) => {
    //     if (element.type === 'multi') {
    //       const subQuestionsArr = this.subQuestionsFromQuestionObj(element, this.maxNumSubQuestions)
    //       questions[idx].subQuestionsArray = subQuestionsArr
    //     }
    //   }
    // )

    this.QuestionsData.questions.forEach(
      (element: IQuestion, idx: number, questions: IQuestion[]) => {
        if (element.type === 'multi') {
          const subQuestionsArr = this.subQuestionsFromQuestionObj(element, this.maxNumSubQuestions)
          questions[idx].subQuestionsArray = subQuestionsArr
        }
      }
    )
  }

  /* Get max number of subquestions from settings element */
  private getMaxNumSubQuestions = () => {
    const multiElement = this.Questions?.categories[0]?.elements?.find(
      (category) => category.type === 'multi'
    )

    let maxNumSelect = this.defaultnumSubQuestions
    if ((multiElement as IMultiInputElement)?.fields) {
      maxNumSelect = Object.keys((multiElement as IMultiInputElement).fields).length
    }
    this.maxNumSubQuestions = maxNumSelect
  }

  private removeSubQuestionsArrayFromQuestions = () => {
    const questionsData = toJS(this.QuestionsData)
    questionsData.questions = questionsData.questions.map(
      // (element: IQuestion) => { // TODO
      (element: { [key: string]: any }) => {
        if (element.type === 'multi' && element.subQuestionsArray) {
          // Remove all 'field' properties from the question
          Object.keys(element).forEach((key) => {
            if (
              key.startsWith('field_') &&
              (key.endsWith('_value') ||
                key.endsWith('_cost_absolute') ||
                key.endsWith('_cost_procentual'))
            ) {
              delete element[key as keyof IQuestion]
            }
          })

          // Re-fill the field properties from the sub-questions array
          const subQuestionsArr = element.subQuestionsArray

          subQuestionsArr.forEach((subquestion: ISubQuestion, index: number) => {
            if (subquestion.value !== undefined) {
              element[(`field_${index + 1}_value` as string) as keyof IQuestion] = subquestion.value
            }
            if (subquestion.cost_absolute !== undefined) {
              element[(`field_${index + 1}_cost_absolute` as string) as keyof IQuestion] =
                subquestion.cost_absolute
            }
            if (subquestion.cost_procentual !== undefined) {
              element[(`field_${index + 1}_cost_procentual` as string) as keyof IQuestion] =
                subquestion.cost_procentual
            }
          })

          // Delete the sub-questions array
          delete element.subQuestionsArray
        }
        return element
      }
    )
    return questionsData
  }

  private subQuestionsFromQuestionObj = (
    questionObj: IQuestion,
    numSubQuestions: number
  ): ISubQuestion[] => {
    const subQuestionsArr: ISubQuestion[] = []
    for (let i = 1; i <= numSubQuestions; i++) {
      if (
        questionObj.hasOwnProperty(`field_${i}_value` as keyof IQuestion) ||
        questionObj.hasOwnProperty(`field_${i}_cost_absolute` as keyof IQuestion) ||
        questionObj.hasOwnProperty(`field_${i}_cost_procentual` as keyof IQuestion)
      ) {
        const value_initial = questionObj[`field_${i}_value` as keyof IQuestion]
        const cost_abs = questionObj[`field_${i}_cost_absolute` as keyof IQuestion]
        const cost_percent = questionObj[`field_${i}_cost_procentual` as keyof IQuestion]

        let value: string | undefined = undefined
        if (typeof value_initial === 'number') {
          value = value_initial + ''
        } else {
          value = value_initial as string | undefined // TODO
        }

        let cost_absolute: number | undefined = undefined
        if (cost_abs !== undefined && typeof cost_abs === 'string') {
          if (!!cost_abs.length) {
            cost_absolute = parseFloat(cost_abs)
            if (isNaN(cost_absolute)) {
              cost_absolute = undefined
            }
          }
          cost_absolute = parseFloat(cost_abs)
        } else if (cost_abs !== undefined && typeof cost_abs === 'number') {
          cost_absolute = cost_abs
        }

        let cost_procentual: number | undefined = undefined
        if (typeof cost_percent !== undefined && typeof cost_percent === 'string') {
          if (!!cost_percent.length) {
            cost_procentual = parseFloat(cost_percent)
            if (isNaN(cost_procentual)) {
              cost_procentual = undefined
            }
          }
        } else if (typeof cost_percent !== undefined && typeof cost_percent === 'number') {
          cost_procentual = cost_percent
        }

        subQuestionsArr.push({
          value,
          cost_absolute,
          cost_procentual,
          id: `${questionObj.id}_${i}`
        })
      }
    }

    return subQuestionsArr
  }

  /** Create a mapping of input elements to categories from the configuration file
   * @param {Object} config - Object containing settings from the configuration file (the
   * 'forms' property of the config.json).
   * @returns An object where each property represents a settings-category. The value of each
   * property is a string array containing the names of all the elements in this category.
   */
  private getElementsPerCategory = (config: {
    [key: string]: ISettings
  }): { [key: string]: string[] } =>
    Object.keys(config).reduce((result: { [key: string]: string[] }, current: string) => {
      if (current === 'questions') {
        return result
      }

      const settings: ISettings = config[current]
      const resultWithCategories = settings.categories.reduce(
        (acc: { [key: string]: string[] }, category: ICategory) => {
          if (!!category.subcategories) {
            category.subcategories?.forEach((subcategory) => {
              acc[subcategory.id] = []
              subcategory.elements?.forEach((element) => {
                acc[subcategory.id].push(`${element.id}${element.postfix}`)
              })
            })
          } else {
            acc[category.id] = []
            category.elements?.forEach((element) => {
              acc[category.id].push(`${element.id}${element.postfix}`)
            })
          }
          return acc
        },
        result
      )
      return resultWithCategories
    }, {})
}

export default FormStore

import React from 'react'
import { observer } from 'mobx-react-lite'
import { Grid, TextField, Typography } from '@material-ui/core'

import { IHeatloadElement } from '../../models/settingsInterfaces'
import { Utility } from '../../utils/Utility'
import NumberFormatCustom from '../utilComponents/NumberFormat'

// data in child component
interface IHeatLoad {
  heatload: number | undefined
  price: number | undefined
  monthlyRate: number | undefined
}

type TPrices = IHeatLoad[]

interface IHeatloadElementProps {
  element: IHeatloadElement
  fieldKey: string
  baseId?: string
  values?: any
  updateData?: (key: string, data: any) => void
}

// Inputs for child component
interface IHeatloadPriceInputsProps {
  baseId: string
  priceNumber: number
  hlPrice: IHeatLoad
  changeCb: (
    index: number,
    field: 'heatload' | 'price' | 'monthlyRate',
    newValue: number | undefined
  ) => void
}

/** observer-Component */
/**
 * @param element - Object containing the properties of the input element.
 * For configurator settings, this data comes from the configuration file.
 * @param fieldKey - For configurator settings, this is an identifier for
 * the setting. It is also used for building the ids of text fields.
 * @param baseId - Optional separate base for the ids of inputs. If not
 * supplied, fieldKey will be used. This might be necessary if the fielKey is not
 * unique across the page.
 * @param values - The data that can be edited in this input
 * @param updateData - 'OnChange' callback for updating data. Will be passed
 * the fieldKey and an object containing the prices and runtime (the latter is
 * currently not used).
 */
const HeatloadElement: React.FC<IHeatloadElementProps> = ({
  element,
  fieldKey,
  baseId,
  values,
  updateData
}: IHeatloadElementProps) => {
  // Keeps track of 'HeatloadPriceInputs' child components state
  const prices = values?.prices ? [...values.prices] : element?.default_data?.prices || []

  /* Probably not needed, input element is hidden in old App. Keeping 
  	value here to keep data consistent with old app */
  const runtime = 10

  if (!baseId) {
    baseId = fieldKey
  }

  // Used in 'HeatloadPriceInputs' child components
  function handlePriceChange(
    index: number,
    field: 'heatload' | 'price' | 'monthlyRate',
    newValue: number | undefined
  ) {
    if (updateData) {
      const newPrices = [...prices]
      newPrices[index] = { ...newPrices[index], [field]: newValue }
      const saveMe: {
        runtime: number | undefined
        prices: TPrices
      } = { runtime, prices: newPrices }
      updateData(fieldKey, saveMe)
    }
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant='body1'>
          <b>{element.title}</b>
        </Typography>
        {element.description && <Typography variant='body1'>{element.description}</Typography>}
      </Grid>
      {/* {prices?.current?.map((price: IHeatLoad, index: number) => ( */}
      {prices?.map((price: IHeatLoad, index: number) => (
        <HeatloadPriceInputs
          key={`priceInput_${index}`}
          baseId={baseId as string}
          priceNumber={index}
          hlPrice={price}
          changeCb={handlePriceChange}
        />
      ))}
    </Grid>
  )
}

/** Contains a set of input fields with labels for heatload, price and monthly rate */
const HeatloadPriceInputs: React.FC<IHeatloadPriceInputsProps> = ({
  baseId,
  priceNumber,
  hlPrice,
  changeCb
}: IHeatloadPriceInputsProps) => {
  const price = hlPrice || { heatload: 0, price: 0, monthlyRate: 0 }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    // Convert input to positive number if needed
    const val = event.target.value ? +Utility.stripNum(event.target.value) : undefined

    // Update prices ref in parent component
    changeCb(priceNumber, event.target.name as 'heatload' | 'price' | 'monthlyRate', val)
  }

  return (
    <Grid container item xs={12} spacing={1}>
      {/* ---- Heatload ---- */}
      <Grid item xs={12} sm={4}>
        <Typography variant='body1' component='label' htmlFor={`${baseId}-hl-${priceNumber}`}>
          Heizlast (kW)
        </Typography>
      </Grid>
      <Grid item xs={12} sm={8}>
        <TextField
          type='text'
          inputProps={{
            decimalSeparator: ',',
            thousandSeparator: '.',
            isNumericString: true,
            maxLength: 2000
          }}
          id={`${baseId}-hl-${priceNumber}`}
          name='heatload'
          value={String(price.heatload)}
          fullWidth
          variant='outlined'
          size='small'
          onChange={handleChange}
          InputProps={{
            inputComponent: NumberFormatCustom as any
          }}
        />
      </Grid>

      {/* ---- Price ---- */}
      <Grid item xs={12} sm={4}>
        <Typography variant='body1' component='label' htmlFor={`${baseId}-pr-${priceNumber}`}>
          Preis (€)
        </Typography>
      </Grid>
      <Grid item xs={12} sm={8}>
        <TextField
          inputProps={{
            maxLength: 2000,
            decimalScale: 2,
            decimalSeparator: ',',
            thousandSeparator: '.',
            fixedDecimalScale: true,
            isNumericString: true
          }}
          id={`${baseId}-pr-${priceNumber}`}
          name='price'
          value={String(price.price)}
          fullWidth
          variant='outlined'
          size='small'
          onChange={handleChange}
          InputProps={{
            inputComponent: NumberFormatCustom as any
          }}
        />
      </Grid>

      {/* ---- Monthly Rate ---- */}
      <Grid item xs={12} sm={4}>
        <Typography variant='body1' component='label' htmlFor={`${baseId}-mr-${priceNumber}`}>
          Monatliche Rate (€/Monat)
        </Typography>
      </Grid>
      <Grid item xs={12} sm={8}>
        <TextField
          inputProps={{
            maxLength: 2000,
            decimalScale: 2,
            decimalSeparator: ',',
            thousandSeparator: '.',
            fixedDecimalScale: true,
            isNumericString: true
          }}
          id={`${baseId}-mr-${priceNumber}`}
          name='monthlyRate'
          value={String(price.monthlyRate)}
          fullWidth
          variant='outlined'
          size='small'
          onChange={handleChange}
          InputProps={{
            inputComponent: NumberFormatCustom as any
          }}
        />
      </Grid>
    </Grid>
  )
}

export default observer(HeatloadElement)

import { action } from 'mobx'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import ReactMediaLibrary from '../../mediaLibrary/src/components/ReactMediaLibrary'
import { useStore } from '../../stores/StoreContext'
import { Utility } from '../../utils/Utility'

const MediaLibraryWrapper: React.FC = () => {
  const { fileStore, uiStore, userStore } = useStore()

  /* Fetch file list on load and when user logs in.*/
  useEffect(() => {
    const initData = action(async () => {
      if (userStore.isLoggedIn) {
        await fileStore.getFileList()
      }
    })
    initData()
  }, [userStore.isLoggedIn])

  function onClose() {
    fileStore.closeMediaLibrary()
  }

  return (
    <>
      {fileStore.filelist && (
        <ReactMediaLibrary
          show={uiStore.mediaLibraryOpen}
          onHide={onClose}
          defaultSelectedTab='library'
          // uploadCallbackReturnType='base64'
          // showUploadTab={false}
          modalTitle={'Media Manager'}
          selectBtnText={'Auswählen'}
          deleteBtnText={'Löschen'}
          uploadTabText={'Hochladen'}
          libraryTabText={'File Browser'}
          dropAreaIdleText={'Klicken Sie oder ziehen Sie eine eine Datei auf diese Fläche...'}
          dropAreaDraggingText={'Loslassen zum Hochladen...'}
          uploadListTitleText={'Hochgeladene Dateien'}
          uploadSuccessText={'Ok'}
          uploadFailedText={'Fehler'}
          uploadProcessingText={'Lädt...'}
          acceptedFormatsText={'Akzeptierte Formate: '}
          maxFileSizeText={'Maximale Dateigröße: '}
          maxNumFilesText={'Maximale Anzahl an Dateien: '}
          sortLabelText={'Sortieren'}
          sortOptionsTexts={
            new Map([
              ['title', 'Titel'],
              ['createdAt', 'Datum'],
              ['size', 'Größe'],
              ['fileName', 'Dateiname']
            ])
          }
          sorOrderLabelText={'Reihenfolge'}
          sorOrderOptionsTexts={
            new Map([
              [true, 'aufsteigend'],
              [false, 'absteigend']
            ])
          }
          showTitle={false}
          showDescription={false}
          itemsPerPage={10}
          maxFileSize={1073741824}
          maxNumFiles={10}
          acceptedTypes={fileStore.mediaLibraryAcceptedTypes}
          fileLibraryList={fileStore.filelist}
          fileUploadCallback={fileStore.fileUploadCallback}
          fileDeleteCallback={fileStore.fileDeleteCallback}
          fileSelectCallback={fileStore.fileSelectCallback}
          renameFile={Utility.renameFile}
        />
      )}
    </>
  )
}

export default observer(MediaLibraryWrapper)

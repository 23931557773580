import React from 'react'
import { observer } from 'mobx-react-lite'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import Checkbox from '@material-ui/core/Checkbox'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import { IGroupInteractElement } from '../../models/settingsInterfaces'
import NumberFormatCustom from '../utilComponents/NumberFormat'
/* eslint-disable arrow-body-style */

// data
interface IGroupInteract {
  rentModel: boolean
  purchaseModel: boolean
  standardSelect: 'purchase' | 'rent' | ''
  leaseRuntime: number
}

// component
interface IGroupInteractElementProps {
  element: IGroupInteractElement
  fieldKey: string
  baseId?: string
  values?: any
  updateData?: (key: string, data: any) => void
}

const getInitialValue = (values: string | boolean | undefined): boolean =>
  values !== undefined ? (typeof values === 'boolean' ? values : values === 'true') : false

/** observer-Component */
/**
 * @param element - Object containing the properties of the input element.
 * For configurator settings, this data comes from the configuration file.
 * @param fieldKey - For configurator settings, this is an identifier for
 * the setting. It is also used for building the ids of text fields.
 * @param baseId - Optional separate base for the ids of inputs. If not
 * supplied, fieldKey will be used. This might be necessary if the fielKey is not
 * unique across the page.
 * @param values - The data that can be edited in this input
 * @param updateData - 'OnChange' callback for updating data. Will be passed
 * the fieldKey and an object containing the prices and runtime (the latter is
 * currently not used).
 */
const GroupInteractElement: React.FC<IGroupInteractElementProps> = ({
  element,
  fieldKey,
  baseId,
  values,
  updateData
}: IGroupInteractElementProps) => {
  const value: IGroupInteract = values || {
    rentModel: false,
    purchaseModel: false,
    standardSelect: 'purchase',
    leaseRuntime: 20
  }
  const rentValue = getInitialValue(value.rentModel)
  const purchaseValue = getInitialValue(value.purchaseModel)

  function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    let newValues: IGroupInteract = value

    if (event.target.type === 'checkbox') {
      newValues = { ...value, [`${event.target.name}`]: event.target.checked }
    } else if (event.target.type === 'radio') {
      newValues = { ...value, standardSelect: event.target.value as 'purchase' | 'rent' | '' }
    } else {
      newValues = { ...value, [`${event.target.name}`]: event.target.value }
    }

    if (updateData) {
      updateData(fieldKey, newValues)
    }
  }

  return (
    <Grid container item xs={12} spacing={2}>
      <Grid item xs={12} sm={12}>
        <Typography variant='body1'>
          <b>{element.title}</b>
        </Typography>
        {element.description && <Typography variant='body1'>{element.description}</Typography>}
      </Grid>

      <Grid container item xs={12} spacing={1} alignItems='baseline'>
        {/* ---- Pachtmodell ---- */}
        <Grid item xs={12} sm={4}>
          <Typography variant='body1' component='label' htmlFor={`${baseId}`}>
            Pachtmodell
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <FormControlLabel
            control={
              <Checkbox
                id={`${fieldKey}-rent`}
                name='rentModel'
                checked={rentValue}
                onChange={handleChange}
                color='primary'
              />
            }
            label='Ja'
          />
        </Grid>

        {/* ---- Kaufmodell ---- */}
        <Grid item xs={12} sm={4}>
          <Typography variant='body1' component='label' htmlFor={`${baseId}`}>
            Kaufmodell
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <FormControlLabel
            control={
              <Checkbox
                id={`${fieldKey}-purchase`}
                name='purchaseModel'
                checked={purchaseValue}
                onChange={handleChange}
                color='primary'
              />
            }
            label='Ja'
          />
        </Grid>

        {/* ---- Standardauswahl ---- */}
        <Grid item xs={12} sm={4}>
          <Typography variant='body1' component='label' htmlFor={`${baseId}`}>
            Standardauswahl
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <RadioGroup
            aria-label='standardSelect'
            name='standardSelect'
            id={`${fieldKey}-standardSelect`}
            value={value.standardSelect}
            onChange={handleChange}>
            <FormControlLabel
              value='rent'
              control={<Radio color='primary' />}
              label='Pachtmodell'
              disabled={!rentValue || !purchaseValue}
            />
            <FormControlLabel
              value='purchase'
              control={<Radio color='primary' />}
              label='Kaufmodell'
              disabled={!rentValue || !purchaseValue}
            />
          </RadioGroup>
        </Grid>

        {/* ---- Laufzeit Pacht ---- */}
        <Grid item xs={12} sm={4}>
          <Typography variant='body1' component='label' htmlFor={`${baseId}-mr`}>
            Laufzeit Pacht (Jahr)
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <TextField
            type='number'
            inputProps={{
              maxLength: 10,
              decimalScale: 0,
              isNumericString: true
            }}
            disabled={!value.rentModel}
            id={`${fieldKey}-leaseRuntime`}
            name='leaseRuntime'
            value={value.leaseRuntime}
            fullWidth
            variant='outlined'
            size='small'
            onChange={handleChange}
            InputProps={{
              inputComponent: NumberFormatCustom as any
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default observer(GroupInteractElement)

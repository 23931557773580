import React from 'react'
import { BrowserRouter, Route, RouteComponentProps, RouteProps, Switch } from 'react-router-dom'

import CISettings from '../../pages/CISettings'
import CMSPage from '../../pages/CMSPage'
import CMSPagesPage from '../../pages/CMSPagesPage'
import EHContents from '../../pages/EHContents'
import EHEmail from '../../pages/EHEmail'
import EHSettings from '../../pages/EHSettings'
import EHWelcome from '../../pages/EHWelcome'
import Fallback404 from '../../pages/Fallback404'
import Hidden from '../../pages/Hidden'
import Home from '../../pages/Home'
import Login from '../../pages/Login'
import Notifications from '../../pages/Notifications'
import Teaser from '../../pages/Teaser'
import User from '../../pages/User'
import WCEmail from '../../pages/WCEmail'
import WTQuestions from '../../pages/WTQuestions'
import WTSettings from '../../pages/WTSettings'
import PVSettings from '../../pages/PVSettings'
import PVEmail from '../../pages/PVEmail'
import PVNotifications from '../../pages/PVNotifications'
import LoggedOutLayout from '../layout/LoggedOutLayout'
import MainLayout from '../layout/MainLayout'
import AdminRoute, { TAdminRouteProps } from './AdminRoute'
import PrivateRoute, { TPrivateRouteProps } from './PrivateRoute'
import SupportRoute, { TSupportRouteProps } from './SupportRoute'

interface IRoute extends Omit<RouteProps, 'component'> {
  component: string
  id: string
}

type TCustomRoute =
  | React.FC<TPrivateRouteProps>
  | React.FC<TSupportRouteProps>
  | React.FC<TAdminRouteProps>
  | typeof Route

interface ILayoutRoute {
  id: string
  layoutComponent: string
  role?: string
  // role?: 'user' | 'admin' | 'support' | 'none'
  routeProps?: RouteProps
  layoutProps?: any
  routes?: IRoute[]
}

interface IRoutesProps {
  routes: ILayoutRoute[]
}

const layoutComponents = {
  LoggedOutLayout,
  MainLayout
}

const pageComponents = {
  Login,
  home: Home,
  CISettings,
  WTQuestions,
  WTSettings,
  EHSettings,
  PVSettings,
  EHContents,
  EHWelcome,
  WCEmail,
  EHEmail,
  PVEmail,
  Notifications,
  PVNotifications,
  CMSPagesPage,
  CMSPage,
  User,
  Home,
  Hidden,
  Teaser,
  Fallback404
}

const routeComponentsByRole = {
  user: PrivateRoute,
  support: SupportRoute,
  admin: AdminRoute,
  none: Route
}

/** Create Routing from routes config.
 * @param routes - Routes configuration object
 */
const Routes: React.FC<IRoutesProps> = ({ routes }: IRoutesProps) => (
  <BrowserRouter>
    <Switch>
      {routes.map((route: ILayoutRoute) => {
        const role = !!route.role && route.role in routeComponentsByRole ? route.role : 'none'
        const layoutComponentName =
          !!route.layoutComponent && route.layoutComponent in layoutComponents
            ? route.layoutComponent
            : 'MainLayout'
        const RouteComponent: TCustomRoute | undefined =
          routeComponentsByRole[role as keyof typeof routeComponentsByRole]
        const LayoutComponent: TCustomRoute =
          layoutComponents[layoutComponentName as keyof typeof layoutComponents]
        const paths: string | string[] | undefined = route.routes?.map(
          (innerRoute: IRoute) => innerRoute.path as string
        )

        return (
          <RouteComponent key={route.id} path={paths} {...route.routeProps}>
            <LayoutComponent {...route.layoutProps}>
              {route.routes?.map((innerRoute: IRoute) => {
                const { component, ...routeProps } = innerRoute
                const pageComponentName =
                  !!component && component in pageComponents ? component : undefined

                const PageComponent:
                  | React.ComponentType<RouteComponentProps<any>>
                  | React.ComponentType<any>
                  | undefined = pageComponentName
                  ? pageComponents[pageComponentName as keyof typeof pageComponents]
                  : undefined

                return <Route key={innerRoute.id} component={PageComponent} {...routeProps} />
              })}
            </LayoutComponent>
          </RouteComponent>
        )
      })}
    </Switch>
  </BrowserRouter>
)

export default Routes

/* eslint-disable arrow-body-style */
import { observer } from 'mobx-react-lite'
import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import { IGroupOfTextElement } from '../../models/settingsInterfaces'
import NumberFormatCustom from '../utilComponents/NumberFormat'
import { Utility } from '../../utils/Utility'

interface ITextValue {
  consumAmount: string | number
  fieldName: string
}

interface IGroupOfTextElementProps {
  element: IGroupOfTextElement
  fieldKey: string
  baseId?: string
  values?: any
  updateData?: (key: string, data: any) => void
}

// Inputs for child component
interface IGroupOfTextInputProps {
  baseId: string
  value: ITextValue
  index: number
  changeCb: (index: number, newValue: number | undefined) => void
}

/** observer-Component */
/**
 * @param element - Object containing the properties of the input element.
 * For configurator settings, this data comes from the configuration file.
 * @param fieldKey - For configurator settings, this is an identifier for
 * the setting. It is also used for building the ids of text fields.
 * @param baseId - Optional separate base for the ids of inputs. If not
 * supplied, fieldKey will be used. This might be necessary if the fielKey is not
 * unique across the page.
 * @param values - The data that can be edited in this input
 * @param updateData - 'OnChange' callback for updating data. Will be passed
 * the fieldKey and an object containing the prices and runtime (the latter is
 * currently not used).
 */

const GroupOfTextElement: React.FC<IGroupOfTextElementProps> = ({
  element,
  fieldKey,
  baseId,
  values,
  updateData
}: IGroupOfTextElementProps) => {
  const items = values?.prices ? [...values.prices] : element?.default_data?.prices || []

  if (!baseId) {
    baseId = fieldKey
  }

  function handlePriceChange(index: number, newValue: number | undefined) {
    if (updateData) {
      const newItems = [...items]
      newItems[index] = { ...newItems[index], consumAmount: newValue }
      updateData(fieldKey, { prices: newItems })
    }
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant='body1'>
          <b>{element.title}</b>
        </Typography>
        {element.description && <Typography variant='body1'>{element.description}</Typography>}
      </Grid>
      {/* {prices?.current?.map((price: IHeatLoad, index: number) => ( */}
      {items?.map((item: ITextValue, index: number) => (
        <GroupOfTextInput
          key={`priceInput_${index}`}
          baseId={baseId as string}
          index={index}
          value={item}
          changeCb={handlePriceChange}
        />
      ))}
    </Grid>
  )
}

const GroupOfTextInput: React.FC<IGroupOfTextInputProps> = ({
  baseId,
  value,
  index,
  changeCb
}: IGroupOfTextInputProps) => {
  function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    // Convert input to positive number if needed
    const val = event.target.value ? +Utility.stripNum(event.target.value) : undefined

    // Update prices ref in parent component
    changeCb(index, val)
  }

  return (
    <Grid container item xs={12} spacing={1}>
      <Grid item xs={12} sm={4}>
        <Typography variant='body1' component='label' htmlFor={`${baseId}-${index}`}>
          {value.fieldName}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={8}>
        <TextField
          inputProps={{
            maxLength: 2000,
            decimalScale: 0,
            decimalSeparator: ',',
            thousandSeparator: '.',
            fixedDecimalScale: true,
            isNumericString: true
          }}
          id={`${baseId}-${index}`}
          name='monthlyRate'
          value={String(value.consumAmount)}
          fullWidth
          variant='outlined'
          size='small'
          onChange={handleChange}
          InputProps={{
            inputComponent: NumberFormatCustom as any
          }}
        />
      </Grid>
    </Grid>
  )
}

export default observer(GroupOfTextElement)

import { Breakpoint } from '@material-ui/core/styles/createBreakpoints'
import { makeAutoObservable } from 'mobx'
import ApplicationStore from './ApplicationStore'

export interface ISnackbarSettings {
  snackbarOpen?: boolean
  snackbarType?: 'info' | 'success' | 'warning' | 'error'
  snackbarMsg?: string
}

export interface IConfirmDialogSettings {
  title?: string
  description?: string
  confirmBtnText?: string
  cancelBtnText?: string
  extraDanger?: boolean
  onConfirm: () => unknown
  onCancel?: () => unknown
  onClose?: () => unknown
}

export interface IPanelOpenSetting {
  [key: string]: string | false
}

class UIStore {
  private rootStore: ApplicationStore
  public drawerOpen: boolean = false
  public mediaLibraryOpen: boolean = false
  public savingDisabled: boolean = false
  public drawerWidth: number = 240
  public drawerOpenBreakpoint: Breakpoint = 'md'
  public showEHGraphicBreakpoint: Breakpoint = 'md'
  public houseGraphicMinHeight = 700
  public topBarHeight = 56
  public btnCanvasViewBoxSize = 42
  public questionTitleMaxLength: number = 70
  public expandedPanels: IPanelOpenSetting = {
    cisettings: false,
    questions: false,
    wtsettings: false,
    ehsettings: false,
    pvsettings: false,
    wcemailsettings: false,
    ehemailsettings: false,
    pvemailsettings: false,
    notifiersettings: false,
    pvnotifiersettings: false,
    ehwelcomesettings: false
  }
  public snackbarSettings: ISnackbarSettings = {
    snackbarOpen: false,
    snackbarType: 'info',
    snackbarMsg: ''
  }
  public confirmDialogOpen: boolean = false
  public confirmDialogConfig: IConfirmDialogSettings | undefined = undefined
  public emphasizeSaveBtn: boolean = false
  public readonly pleaseSaveYourSettingsText: string =
    'Bitte sichern Sie ihre Einstellungen, bevor Sie die Seite verlassen oder neu laden!'
  public readonly onLeaveText: string =
    'Sie haben ungesicherte Änderungen. ' +
    'Wenn Sie die Seite verlassen, gehen die Änderungen verloren. ' +
    'Um die Änderugen zu speichern, klicken Sie auf SPEICHERN oben rechts. '

  public constructor(rootStore: ApplicationStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  public toggleDrawerOpen = () => {
    this.drawerOpen = !this.drawerOpen
  }

  public setExpandedPanel = (settingsId: string, panelId: string | false) => {
    this.expandedPanels[settingsId] = panelId
  }

  public setSnackbarSettings = (newSettings: ISnackbarSettings) => {
    this.snackbarSettings = { ...this.snackbarSettings, ...newSettings }
  }

  // Shorthand for displaying any success messsage
  public setSnackbarSuccess = (successMsg: string) => {
    this.setSnackbarSettings({
      snackbarOpen: true,
      snackbarType: 'success',
      snackbarMsg: successMsg
    })
  }

  // Shorthand for displaying any error messsage
  public setSnackbarError = (errorMsg: string) => {
    this.setSnackbarSettings({
      snackbarOpen: true,
      snackbarType: 'error',
      snackbarMsg: errorMsg
    })
  }

  // Shorthand for displaying a success messsage once data is saved
  public setDataSavedSnackbarSuccess = () => {
    this.setSnackbarSettings({
      snackbarOpen: true,
      snackbarType: 'success',
      snackbarMsg: 'Ihre Daten wurden gespeichert'
    })
  }

  // Shorthand for displaying a error messsage once data is saved
  public setDataSavedSnackbarError = () => {
    this.setSnackbarSettings({
      snackbarOpen: true,
      snackbarType: 'error',
      snackbarMsg:
        'Ihre Daten konnten nicht gespeichert werden. Bitte versuchen Sie es noch einmal.'
    })
  }

  public setConfirmDialogOpen = (isOpen: boolean) => {
    this.confirmDialogOpen = isOpen
  }

  public setConfirmDialogConfig = (config: IConfirmDialogSettings | undefined) => {
    this.confirmDialogConfig = config
  }

  public openConfirmDialog = (config: IConfirmDialogSettings) => {
    this.setConfirmDialogConfig(config)
    this.setConfirmDialogOpen(true)
  }

  public setMediaLibraryOpen = (isOpen: boolean) => {
    this.mediaLibraryOpen = isOpen
  }

  public setSavingDisabled = (saving: boolean) => {
    this.savingDisabled = saving
  }

  public setEmphasizeSaveBtn = (doEmphasize: boolean) => {
    this.emphasizeSaveBtn = doEmphasize
  }

  // The app bar will use this to save it's height, so it can be read by other components
  public setTopBarHeight = (height: number) => {
    this.topBarHeight = height
  }
}

export default UIStore

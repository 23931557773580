import React from 'react'
import { IHeatloadElementWB } from '../../models/settingsInterfaces'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import { Utility } from '../../utils/Utility'
import NumberFormatCustom from '../utilComponents/NumberFormat'
import { observer } from 'mobx-react-lite'

// data in child component
interface IHeatLoadWB {
  capacity: number | undefined
  facilityPower: number | undefined
  smartPrice: number | undefined
}

interface IHeatloadElementWBProps {
  element: IHeatloadElementWB
  fieldKey: string
  baseId?: string
  values?: any
  updateData?: (key: string, data: any) => void
}

interface IHeatloadFPVInputsProps {
  baseId: string
  index: number
  price: IHeatLoadWB
  isLast: boolean
  changeCb: (
    index: number,
    field: 'facilityPower' | 'capacity' | 'smartPrice',
    newValue: number | undefined
  ) => void
}

/** observer-Component */
/**
 * @param element - Object containing the properties of the input element.
 * For configurator settings, this data comes from the configuration file.
 * @param fieldKey - For configurator settings, this is an identifier for
 * the setting. It is also used for building the ids of text fields.
 * @param baseId - Optional separate base for the ids of inputs. If not
 * supplied, fieldKey will be used. This might be necessary if the fielKey is not
 * unique across the page.
 * @param values - The data that can be edited in this input
 * @param updateData - 'OnChange' callback for updating data. Will be passed
 * the fieldKey and an object containing the prices and runtime (the latter is
 * currently not used).
 */
const HeatloadElementWB: React.FC<IHeatloadElementWBProps> = ({
  element,
  fieldKey,
  baseId,
  values,
  updateData
}: IHeatloadElementWBProps) => {
  // Keeps track of 'HeatloadPriceInputs' child components state
  const prices = values?.prices ? [...values.prices] : element?.default_data?.prices || []

  if (!baseId) {
    baseId = fieldKey
  }

  // Used in 'HeatloadPriceInputs' child components
  function handlePriceChange(
    index: number,
    field: 'facilityPower' | 'capacity' | 'smartPrice',
    newValue: number | undefined
  ) {
    if (updateData) {
      const newPrices = [...prices]
      newPrices[index] = { ...newPrices[index], [field]: newValue }
      updateData(fieldKey, { prices: newPrices })
    }
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant='body1'>
          <b>{element.title}</b>
        </Typography>
        {element.description && <Typography variant='body1'>{element.description}</Typography>}
      </Grid>
      {prices?.map((price: IHeatLoadWB, index: number) => (
        <HeatloadFPVInputs
          key={`priceInput_${index}`}
          baseId={baseId as string}
          index={index}
          price={price}
          changeCb={handlePriceChange}
          isLast={index === prices.length - 1}
        />
      ))}
    </Grid>
  )
}

/** Contains a set of input fields with labels for heatload, price and monthly rate */
const HeatloadFPVInputs: React.FC<IHeatloadFPVInputsProps> = ({
  baseId,
  index,
  price,
  changeCb,
  isLast
}: IHeatloadFPVInputsProps) => {
  const wBPrice = price || { facilityPower: 0, price: 0, monthlyRate: 0 }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    // Convert input to positive number if needed
    const val = event.target.value ? +Utility.stripNum(event.target.value) : undefined

    // Update prices ref in parent component
    changeCb(index, event.target.name as 'facilityPower' | 'capacity' | 'smartPrice', val)
  }

  return (
    <Grid container item xs={12} spacing={1}>
      {/* ---- facility power ---- */}
      <Grid item xs={12} sm={4}>
        <Typography variant='body1' component='label' htmlFor={`${baseId}-cp-${index}`}>
          {isLast
            ? 'Kapazität des Autos größer als (kWh)'
            : 'Kapazität des Autos kleiner als (kWh)'}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={8}>
        <TextField
          type='text'
          inputProps={{
            decimalScale: 0,
            decimalSeparator: ',',
            thousandSeparator: '.',
            isNumericString: true,
            maxLength: 2000
          }}
          id={`${baseId}-cp-${index}`}
          name='capacity'
          value={String(wBPrice.capacity)}
          fullWidth
          variant='outlined'
          size='small'
          onChange={handleChange}
          InputProps={{
            inputComponent: NumberFormatCustom as any
          }}
        />
      </Grid>

      {/* ---- price ---- */}
      <Grid item xs={12} sm={4}>
        <Typography variant='body1' component='label' htmlFor={`${baseId}-fp-${index}`}>
          Anlagenleistung (kW)
        </Typography>
      </Grid>
      <Grid item xs={12} sm={8}>
        <TextField
          inputProps={{
            maxLength: 2000,
            decimalScale: 2,
            decimalSeparator: ',',
            thousandSeparator: '.',
            fixedDecimalScale: true,
            isNumericString: true
          }}
          id={`${baseId}-fp-${index}`}
          name='facilityPower'
          value={String(wBPrice.facilityPower)}
          fullWidth
          variant='outlined'
          size='small'
          onChange={handleChange}
          InputProps={{
            inputComponent: NumberFormatCustom as any
          }}
        />
      </Grid>

      {/* ---- monthlyRate ---- */}
      <Grid item xs={12} sm={4}>
        <Typography variant='body1' component='label' htmlFor={`${baseId}-pr-${index}`}>
          Preis der Wallbox (€)
        </Typography>
      </Grid>
      <Grid item xs={12} sm={8}>
        <TextField
          inputProps={{
            maxLength: 2000,
            decimalScale: 0,
            decimalSeparator: ',',
            thousandSeparator: '.',
            fixedDecimalScale: true,
            isNumericString: true
          }}
          id={`${baseId}-pr-${index}`}
          name='smartPrice'
          value={String(wBPrice.smartPrice)}
          fullWidth
          variant='outlined'
          size='small'
          onChange={handleChange}
          InputProps={{
            inputComponent: NumberFormatCustom as any
          }}
        />
      </Grid>
    </Grid>
  )
}

export default observer(HeatloadElementWB)

import React from 'react'
import { observer } from 'mobx-react-lite'
import { FormControl, FormHelperText, TextField, TextFieldProps } from '@material-ui/core'

import { ITextInputElement } from '../../models/settingsInterfaces'
import { Utility } from '../../utils/Utility'
import SimpleInputBase from './SimpleInputBase'
import { NumberFormatProps } from 'react-number-format'
import NumberFormatCustom from '../utilComponents/NumberFormat'

interface IValues {
  question: string
  questionLong?: string
  order?: string | number
}

interface ITextElementProps {
  element: ITextInputElement
  fieldKey: string
  baseId?: string
  values?: IValues
  updateData?: (key: string, data: any) => void
  askQuestionLong?: boolean // Probably never used, just here to stay consistent with old app
  saveObj?: boolean
  numberFormatProps?: NumberFormatProps
}

/** observer-Component */
/**
 *
 * @param {Object} element - Object containing the properties of the input element.
 * For configurator settings, this data comes from the configuration file.
 * @param {string} fieldKey - For configurator settings, this is an identifier for
 * the setting. It is also used as an id for the text field.
 * @param {string} baseId - Optional separate base for the ids of inputs. If not
 * supplied, fieldKey will be used. This might be necessary if the fielKey is not
 * unique across the page.
 * @param {(Object)} values - The data that can be edited in this input. An object
 * with a 'question' property and an optional 'questionLong' property. The latter
 * is probably never used and only included to stay consistent with an older version
 * of this app.
 * @param {function} updateData - 'OnChange' callback for updating data.
 * @param {boolean} askQuestionLong - Probably never used, just here to stay consistent
 * with an older version of this app
 * @param {boolean} saveObj - If false, data will be stored 'as is', otherweise it
 * will be wrapped in an object (needed for most of the configurator settings)
 * @param {boolean} numberFormatProps - If this prop is present, inputs will be
 * formatted using react-number-format, according to the settings in this prop
 *
 * Additionally, this component accepts all attributes that can be used with
 * the MUI 'TextField' component
 */
const TextElement: React.FC<ITextElementProps & TextFieldProps> = ({
  element,
  fieldKey,
  baseId,
  values,
  updateData,
  askQuestionLong = false,
  saveObj = true, // For CISettings, data is not stored in a question-object
  numberFormatProps,
  ...otherProps
}: ITextElementProps) => {
  const question = values?.question || ''
  const questionLong = values?.questionLong || undefined
  const { inputProps, ...restProps } = otherProps as TextFieldProps

  /* Probably not needed, but included here to keep data consistent with with an older 
  version of this app. */
  const order = values?.order || undefined

  /* Do we expect number or text inputs? If we have 'numberFormatProps' the input will be
  a string formatted as a number. */
  const isNumberInput = !!!numberFormatProps && element.validate === 'num'

  if (!baseId) {
    baseId = fieldKey
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const val =
      element.validate && element.validate === 'num'
        ? Utility.stripNum(event.target.value)
        : event.target.value

    if (updateData) {
      /* For CISettings, data is stored directly, for all other settings, it 
	  is wrapped in an object */
      if (!saveObj) {
        updateData(fieldKey, val)
      } else {
        const saveMe: {
          question: string
          questionLong?: string | undefined
          order?: string | number | undefined
        } = { question: val }

        if (askQuestionLong) {
          saveMe.questionLong = questionLong
        }
        if (order) {
          saveMe.order = order
        }
        updateData(fieldKey, saveMe)
      }
    }
  }

  function handleChangeQuestionLong(event: React.ChangeEvent<HTMLInputElement>) {
    if (updateData) {
      const saveMe: {
        question: string
        questionLong: string
        order: string | number | undefined
      } = { question, questionLong: event.target.value, order }
      updateData(fieldKey, saveMe)
    }
  }

  return (
    <SimpleInputBase
      fullWidth={element.fullWidth || false}
      firstRowTitle={element.title || 'Frage'}
      firstRowId={`input_${baseId}`}
      toolTip={element.toolTip}
      firstRowChildren={
        <FormControl fullWidth>
          <TextField
            type={isNumberInput ? 'number' : 'text'}
            id={`input_${baseId}`}
            value={question}
            fullWidth
            variant='outlined'
            size='small'
            onChange={handleChange}
            aria-describedby={`description_${baseId}`}
            InputProps={{
              inputComponent: !!numberFormatProps ? (NumberFormatCustom as any) : 'input'
            }}
            inputProps={{ ...inputProps, ...numberFormatProps }}
            {...restProps}
          />
          {element.description && (
            <FormHelperText id={`description_${baseId}`}>{element.description}</FormHelperText>
          )}
        </FormControl>
      }
      secondRowTitle={askQuestionLong ? 'Erklärung, unterhalb der Frage' : undefined}
      secondRowId={askQuestionLong ? `questionLong_${baseId}` : undefined}
      secondRowChildren={
        askQuestionLong ? (
          <FormControl fullWidth>
            <TextField
              type={'text'}
              id={`questionLong-${baseId}`}
              value={questionLong}
              fullWidth
              variant='outlined'
              size='small'
              onChange={handleChangeQuestionLong}
            />
          </FormControl>
        ) : undefined
      }
    />
  )
}

export default observer(TextElement)

import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Grid, TextField, Typography } from '@material-ui/core'

import InputsContainer from './InputsContainer'
import TextFieldInnerLabel from '../molecules/TextFieldInnerLabel'
import { IQuestion } from '../../models/questionsInterfaces'
import { useErrors } from '../../utils/customHooks'

interface IRangeElementProps {
  questionData: IQuestion
  baseId: string
  updateData?: (id: string, field: string, value: any) => void
  setSettingError?: (id: string) => void
  clearSettingError?: (id: string) => void
}

/** observer-Component */
const RangeElement: React.FC<IRangeElementProps> = ({
  questionData,
  baseId,
  updateData,
  setSettingError,
  clearSettingError
}: IRangeElementProps) => {
  const { errors, setError, clearError } = useErrors()

  function handleQuestionChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (updateData) {
      updateData(questionData.id, 'question', event.target.value)
    }
  }

  function handleQuestionLongChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (updateData) {
      updateData(questionData.id, 'questionLong', event.target.value)
    }
  }

  function handleMaxChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (updateData) {
      updateData(questionData.id, 'max', event.target.value)
    }

    if (!!event.target.value.length) {
      const newval =
        typeof event.target.value === 'number' ? event.target.value : parseFloat(event.target.value)

      if (questionData.min && newval < questionData.min) {
        setError('min', 'Der Minimalwert ist größer als der Maximalwert.')

        if (setSettingError) {
          setSettingError(questionData.id)
        }
      } else {
        clearError('min')
        if (clearSettingError) {
          clearSettingError(questionData.id)
        }
      }
    } else {
      clearError('min')
      if (clearSettingError) {
        clearSettingError(questionData.id)
      }
    }
  }

  function handleMinChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (updateData) {
      updateData(questionData.id, 'min', event.target.value)
    }

    if (!!event.target.value.length) {
      const newval =
        typeof event.target.value === 'number' ? event.target.value : parseFloat(event.target.value)

      if (questionData.max && newval > questionData.max) {
        setError('min', 'Der Minimalwert ist größer als der Maximalwert.')
        if (setSettingError) {
          setSettingError(questionData.id)
        }
      } else {
        clearError('min')
      }
    } else {
      clearError('min')
    }
  }

  // Use effect to notify parents if all errors have gone
  useEffect(() => {
    if (clearSettingError && !!!Object.keys(errors).length) {
      clearSettingError(questionData.id)
    }
  }, [errors])

  return (
    <InputsContainer>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant='body1' component='label' htmlFor={`${baseId}-q`}>
            <b>Frage</b>
          </Typography>

          <TextField
            id={`${baseId}-q`}
            value={questionData.question || ''}
            fullWidth
            variant='outlined'
            size='small'
            inputProps={{
              maxLength: 2000
            }}
            onChange={handleQuestionChange}
            error={!!errors.question}
            helperText={errors.question ? errors.question : ''}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant='body1' component='label' htmlFor={`${baseId}-ql`}>
            Erklärung, unterhalb der Frage
          </Typography>

          <TextField
            id={`${baseId}-ql`}
            value={questionData.questionLong || ''}
            fullWidth
            variant='outlined'
            size='small'
            inputProps={{
              maxLength: 2000
            }}
            onChange={handleQuestionLongChange}
            error={!!errors.questionLong}
            helperText={errors.questionLong ? errors.questionLong : ''}
          />
        </Grid>

        <Grid item xs={6}>
          <TextFieldInnerLabel
            type='number'
            id={`${baseId}-min`}
            value={String(questionData.min || undefined)}
            label='Min'
            maxLength={2000}
            onChange={handleMinChange}
            error={errors.min ? 1 : 0}
            helperText={errors.min ? errors.min : ''}
          />
        </Grid>

        <Grid item xs={6}>
          <TextFieldInnerLabel
            type='number'
            id={`${baseId}-max`}
            value={String(questionData.max || undefined)}
            label='Max'
            maxLength={2000}
            onChange={handleMaxChange}
            error={errors.max ? 1 : 0}
            helperText={errors.max ? errors.max : ''}
          />
        </Grid>
      </Grid>
    </InputsContainer>
  )
}

export default observer(RangeElement)

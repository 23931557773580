import React, { useState } from 'react'
import { createStyles, makeStyles, Button, Grid, TextField, Typography } from '@material-ui/core'
import { observer } from 'mobx-react-lite'
import { Redirect } from 'react-router-dom'
import PageTitle from '../components/atoms/PageTitle'
import { useStore } from '../stores/StoreContext'

interface IErrors {
  username?: string
  password?: string
}

/** observer-Component */
const Login: React.FC = () => {
  const classes = useStyles()
  const { userStore } = useStore()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [errors, setErrors] = useState<IErrors>({})

  if (!userStore.isLoading && userStore.isLoggedIn) {
    return <Redirect to='/' />
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.name === 'username') {
      setUsername(event.target.value)
    } else if (event.target.name === 'password') {
      setPassword(event.target.value)
    }
  }

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()

    const tmpErrors: IErrors = {}
    if (!username) {
      tmpErrors.username = 'Bitte geben Sie ihren Benutzernamen ein.'
    }

    if (!password) {
      tmpErrors.password = 'Bitte geben Sie ihr Passwort ein.'
    }

    if (Object.entries(tmpErrors).length > 0) {
      setErrors(tmpErrors)
      return
    } else {
      setErrors({})
    }

    userStore.login(username, password)
  }

  return (
    <>
      <PageTitle>ASEW Konfigurator</PageTitle>
      <Typography variant='body1' gutterBottom>
        Herzlich Willkommen! Bitte melden Sie sich an.
      </Typography>
      <br />

      <form className={classes.formRoot} onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} container direction='column'>
            <TextField
              label='Benutzername'
              inputProps={{
                maxLength: 2000
              }}
              id='username'
              name='username'
              value={username}
              variant='outlined'
              size='small'
              onChange={handleChange}
              error={!!errors.username}
              helperText={errors.username ? errors.username : null}
            />
          </Grid>

          <Grid item xs={12} container direction='column'>
            <TextField
              type='password'
              label='Passwort'
              inputProps={{
                maxLength: 2000
              }}
              id='password'
              name='password'
              value={password}
              variant='outlined'
              size='small'
              onChange={handleChange}
              error={!!errors.password}
              helperText={errors.password ? errors.password : null}
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant='contained' color='primary' type='submit'>
              Login
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  )
}

const useStyles = makeStyles(
  createStyles({
    formRoot: {
      width: '100%',
      maxWidth: '400px'
    }
  })
)

export default observer(Login)
